<template>
  <div
    class="bg-dark text-white text-xs p-8 grid grid-cols-2 gap-4 overflow-scroll fixed w-full h-full"
  >
    <!--Left Col-->
    <div>
      <h1 class="mt-0">Version</h1>
      <div>{{ version }}</div>
      <h1>Links</h1>
      <section>
        <RouterLink to="/">Login</RouterLink> |
        <RouterLink to="/forgot-password">Forgot Password</RouterLink> |
        <RouterLink to="/forgot-password-verification"> Reset Password Email Link </RouterLink>
      </section>
      <section>
        <RouterLink to="/dashboard">Dashboard</RouterLink> |
        <RouterLink to="/send-money">SendMoney</RouterLink> -
        <RouterLink to="/send-money/recipient">SendMoneyRecipient</RouterLink>
      </section>
      <section>
        <RouterLink to="/registration">Registration</RouterLink> -
        <RouterLink to="/registration/phone">RegisterPhone</RouterLink> -
        <RouterLink to="/registration/password">RegisterPassword</RouterLink> -
        <RouterLink to="/registration/creating">RegisterCreating</RouterLink>
      </section>
      <section>
        <RouterLink to="/otp">OTP</RouterLink> -
        <RouterLink to="/otp/registration">OTP - Register</RouterLink>
      </section>
      <h1>Controls</h1>
      <AppInputSelect
        v-model="locale"
        analytics-name="dev-locale"
        label="Locale"
        class="locale-select"
        :options="localeOptions"
      ></AppInputSelect>
      <AppInputSelect
        v-model="env"
        analytics-name="dev-env"
        label="Environment"
        class="locale-select"
        :options="envOptions"
      ></AppInputSelect>
      <label class="input-label">Should Fake Public Site Cookie</label>
      <AppInputCheckbox
        v-model="shouldFakePublicSiteCookie"
        analytics-name="dev-shouldFakePublicSiteCookie"
        label="Should Fake Public Site Cookie"
        class="locale-cookie-checkbox"
        >Should Fake Public Site Cookie</AppInputCheckbox
      >
      <AppButton @click="start">Start</AppButton>
    </div>
    <!--Right Col-->
    <div>
      <h1 class="mt-0">Environment</h1>
      <pre>{{ selectedEnv }}</pre>
      <h1>Environment Variables</h1>
      <pre>{{ vars }}</pre>
      <h1>Locale</h1>
      <p>Translation Key <code>$t</code> test: {{ $t('PageLogin.Title').value }}</p>
      <pre>{{ i18n }}</pre>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import {
  useDeviceStore,
  useI18nStore,
  useDevStore,
  useEnvStore,
  useAppStore,
} from '@galileo/stores'

import { AppInputCheckbox, AppInputSelect, AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'Dev',
  components: {
    AppInputCheckbox,
    AppInputSelect,
    AppButton,
  },
  setup() {
    const devStore = useDevStore()
    const envStore = useEnvStore()
    const i18n = useI18n()
    const deviceStore = useDeviceStore()
    const { $t } = i18n
    const router = useRouter()
    const appStore = useAppStore()

    // Locale
    const locale = computed({
      get: () => deviceStore.getLocale,
      set: (value) => {
        deviceStore.fetchLocale(value)
      },
    })

    const localeOptions = computed(() => {
      return i18n.locales.map((item) => ({ text: item, value: item }))
    })

    // Environment
    const env = computed({
      get: () => devStore.environment,
      set: (value) => {
        devStore.setEnvironment(value)
        appStore.init()
      },
    })
    const envs = devStore.environments
    const envOptions = Object.keys(envs).map((key) => ({ text: key, value: key }))
    devStore.setEnvironments(envs)

    const selectedEnv = computed(() => {
      return {
        [env.value]: devStore.getSelectedEnvironment,
      }
    })

    // Public Site Cookie
    const shouldFakePublicSiteCookie = computed({
      get: () => devStore.shouldFakePublicSiteCookie,
      set: (value) => {
        devStore.setShouldFakePublicSiteCookie(value)
        appStore.init()
      },
    })

    // Vars
    const vars = envStore.env

    const start = () => {
      router.push({ name: 'Login' })
    }

    return {
      $t,
      localeOptions,
      locale,
      i18n,
      version: vars.VUE_APP_XE_VERSION,
      env,
      envOptions,
      selectedEnv,
      vars,
      shouldFakePublicSiteCookie,
      start,
    }
  },
}
</script>

<style scoped>
h1 {
  @apply type-h1 mb-1 mt-4;
}
img {
  @apply w-48 m-auto;
}
.locale-select {
  @apply text-primary-text;
  ::v-deep .input-label {
    @apply text-white;
  }
}
.locale-cookie-checkbox {
  ::v-deep .input-checkbox-virtual-checkbox-check {
    @apply border-white;
  }
}
</style>
